import React, { Component, Fragment } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import members from "../../utils/members.json";
import Navbar from "../partials/Navbar";
import moment from "moment";
import queryString from "query-string";
import $ from "jquery";
import { logoutUser } from "../../actions/authActions";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import CustomAlert from "./View/CustomAlert";
class OrderList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "date_formatted",
        text: "Date",
        className: "date_formatted",
        align: "left",
        sortable: true,
      },
      {
        key: "order_id",
        text: "Order Id",
        className: "order_id",
        align: "left",
        sortable: true,
      },
      {
        key: "store",
        text: "REP",
        className: "store",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "qty5mgTotal",
      //   text: "Semaglutide5mg Total",
      //   className: "qty5mgTotal",
      //   align: "left",
      //   currency: true,
      // },
      // {
      //   key: "qty10mgTotal",
      //   text: "10mg Total",
      //   className: "qty10mgTotal",
      //   align: "left",
      //   currency: true,
      // },
      // {
      //   key: "qty20mgTotal",
      //   text: "20mg Total",
      //   className: "qty20mgTotal",
      //   align: "left",
      //   currency: true,
      // },
      {
        key: "qty1mgTotal",
        text: "Semaglutide-1mg/ml",
        className: "qty1mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qty2M5mgTotal",
        text: "Semaglutide-2.5mg/ml",
        className: "qty2M5mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qty5M2mgTotal",
        text: "Semaglutide-5mg/2ml",
        className: "qty5M2mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qty15mgTotal",
        text: "Semaglutide-7.5mg/3ml",
        className: "qty15mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qty40mgTotal",
        text: "Semaglutide-10mg/4ml",
        className: "qty40mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qtyt20mgTotal",
        text: "Tirzepatide-20mg/2ml",
        className: "qtyt20mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qtyt60mgTotal",
        text: "Tirzepatide-60mg/3ml",
        className: "qtyt60mgTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qtysemaApiTotal",
        text: "API Semaglutide Total",
        className: "qtysemaApiTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qtytirzApiTotal",
        text: "API Tirzepatide Total",
        className: "qtytirzApiTotal",
        align: "left",
        currency: true,
      },
      {
        key: "qtyTotal",
        text: "Order Total",
        className: "qtyTotal",
        align: "left",
        currency: true,
      },
    ];

    if (this.props.auth.user.email === "mehrdadhairi@gmail.com") {
      this.columns.push({
        key: "delete",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <button
              className="btn btn-danger btn-sm"
              onClick={() => this.deleteRecord(record)}
            >
              <i className="fa fa-trash"></i>
            </button>
          );
        },
      });
    } else {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <a href="#">
              <span onClick={() => this.showPdf(record)}>PDF</span>
            </a>
          );
        },
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100, 500],
      filename: "Order List",
      no_data_text: "No Log found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      sort: {
        column: "date",
        order: "desc",
      },
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "date", order: "desc" },
      },
      currentRecord: {},
      date: undefined,
      currentUser: {},
      emailFilter: undefined,
      userData: [],
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (queryString.parse(window.location.search).email) {
      this.setState(
        { emailFilter: queryString.parse(window.location.search).email },
        () => this.getData()
      );
    } else {
      this.getData();
    }
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    const memberD = Object.values(members).find(
      (eachD) => eachD.email === this.props.auth.user.email
    );
    filterData.search = {
      ...(this.state.rep !== "" && { rep: this.state.rep }),
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { from_date: this.state.to_date }),
      ...(memberD && { rep: memberD.code }),
      email: this.state.emailFilter
        ? this.state.emailFilter
        : this.props.auth.user.email,
      ...(this.state.emailFilter === undefined && {
        superadmin: this.props.auth.user.superadmin,
      }),
    };
    axios
      .post("/api/order-list", filterData)
      .then((res) => {
        var result = [];
        let emails = [];
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          each.date_formatted = each.date ? moment(each.date).format("L") : "";
          const memberD = Object.values(members).find(
            (eachD) => eachD.code === each.rep
          );
          each.store = memberD ? memberD.name : "";
          emails.push(each.email);
          result.push(each);
        }
        this.getUsers(emails);
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  deleteRecord(record) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlert
            onClose={onClose}
            onChange={this.onChange}
            handleCheckPin={this.handleCheckPin}
            record={record}
          />
        );
      },
    });
  }

  handleCheckPin = (record) => {
    if (this.state.pin === "1613") {
      this.finalDelete(record);
    } else {
      alert("Pin Invalid");
    }
  };
  finalDelete = (record) => {
    axios
      .post("/api/order-delete", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  getUsers(users) {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "date", order: "desc" },
    };

    filterData.search = {
      users,
    };
    axios
      .post("/api/users", filterData)
      .then((res) => {
        this.setState({
          userData: res.data.records,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  resetSearch = () => {
    this.setState(
      {
        rep: undefined,
        filterData: {
          sort_order: { column: "_id", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  showPdf = (record) => {
    this.setState({ currentRecord: record }, () => {
      this.getUserData(record.email);
    });
  };

  getUserData = (email) => {
    this.setState({ loading: true });
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "date", order: "desc" },
    };
    filterData.search = {
      email,
      orderList: true,
    };
    axios
      .post("/api/users", filterData)
      .then((res) => {
        this.setState(
          {
            currentUser: res.data.records[0],
            loading: false,
          },
          () => this.printPDF()
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  printPDF = () => {
    var iframe = document.getElementById("printingFrame");
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.id = "printingFrame";
      iframe.name = "printingFrame";
      iframe.width = "0";
      iframe.height = "0";
      iframe.style = "position: absolute;top: 0;left: 0;width: 0;height: 0;";
      document.body.appendChild(iframe);
    }

    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write("<!DOCTYPE html");
    iframe.contentWindow.document.write("<html><head>");
    iframe.contentWindow.document.write("<title>Order</title>");
    iframe.contentWindow.document.write(
      '<style type="text/css">' +
      "body{font-family:Verdana, Arial;font-size:12px;}" +
      "@media all {.page-break { display: none; }}" +
      "@media print {.page-break { display: block; page-break-before: always; }}" +
      "</style>"
    );
    iframe.contentWindow.document.write("</head><body>");
    iframe.contentWindow.document.write($("#showPdf").html());
    iframe.contentWindow.document.write("</body></html>");
    iframe.contentWindow.document.close();

    window.frames["printingFrame"].focus();
    window.frames["printingFrame"].print();
  };

  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      date_formatted: "Date",
      order_id: "Order Id",
      name: "Name",
      store: "REP",
      email: "Email",
      qty1mgTotal: "Semaglutide-1mg/ml",
      qty2M5mgTotal: "Semaglutide-2.5mg/ml",
      qty5M2mgTotal: "Semaglutide-5mg/2ml",
      qty15mgTotal: "Semaglutide-7.5mg/3ml",
      qty40mgTotal: "Semaglutide-10mg/4ml",
      qty80mgTotal: "Semaglutide-20mg/4ml",
      qtyt10mgTotal: "Tirzepatide-10mg/2ml",
      qtyt20mgTotal: "Tirzepatide-20mg/2ml",
      qtyt30mgTotal: "Tirzepatide-30mg/2ml",
      qtyt40mgTotal: "Tirzepatide-40mg/2ml",
      qtyt50mgTotal: "Tirzepatide-50mg/2ml",
      qtyt60mgTotal: "Tirzepatide-60mg/3ml",
      qtysemaApiTotal: "API Semaglutide Total",
      qtytirzApiTotal: "API Tirzepatide Total",
      qtyTotal: "Order Total",
    };
    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "date", order: "desc" },
    };
    const memberD = Object.values(members).find(
      (eachD) => eachD.email === this.props.auth.user.email
    );
    filterData.search = {
      ...(this.state.rep !== "" && { rep: this.state.rep }),
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { from_date: this.state.to_date }),
      ...(memberD && { rep: memberD.code }),
      email: this.state.emailFilter
        ? this.state.emailFilter
        : this.props.auth.user.email,
      ...(this.state.emailFilter === undefined && {
        superadmin: this.props.auth.user.superadmin,
      }),
    };
    axios
      .post("/api/order-list", filterData)
      .then((res) => {
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          delete each._id;
          delete each.id;
          delete each.__v;
          delete each.user;
          each.date_formatted = each.date ? moment(each.date).format("L") : "";
          const memberD = Object.values(members).find(
            (eachD) => eachD.code === each.rep
          );
          let filterD = this.state.userData.find(
            (eachUser) => eachUser.email === each.email
          );
          each.name = filterD ? filterD.name : "";
          each.store = memberD ? memberD.name : "";
          records.push(each);
        }
        if (headers) {
          records.unshift(headers);
        }
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  render() {
    let records = (this.state.records || []).map((each) => {
      let filterD = this.state.userData.find(
        (eachUser) => eachUser.email === each.email
      );
      each.name = filterD ? filterD.name : "";
      return each;
    });
    console.log(this.state.currentUser);
    const {
      order_id,
      date,
      shipping = 0,
      qty5mgTotal,
      qty5mg,
      price5mg,
      qty10mg,
      qty10mgTotal,
      price10mg,
      qty20mg,
      qty20mgTotal,
      price20mg,
      qtyTotal,
      sema_api,
      sema_price,
      qty_sema_api,
      qtysemaApiTotal,
      tirz_api,
      tirz_price,
      qty_tirz_api,
      qtytirzApiTotal,
      store,
      qty1mg,
      qty2M5mg,
      qty5M2mg,
      qty15mg,
      qty40mg,
      qty80mg,
      qtyt10mg,
      qtyt20mg,
      qtyt30mg,
      qtyt40mg,
      qtyt50mg,
      qtyt60mg,
      price1mg,
      price2M5mg,
      price5M2mg,
      price15mg,
      price40mg,
      price80mg,
      pricet10mg,
      pricet20mg,
      pricet30mg,
      pricet40mg,
      pricet50mg,
      pricet60mg,
      qty1mgTotal,
      qty2M5mgTotal,
      qty5M2mgTotal,
      qty15mgTotal,
      qty40mgTotal,
      qty80mgTotal,
      qtyt10mgTotal,
      qtyt20mgTotal,
      qtyt30mgTotal,
      qtyt40mgTotal,
      qtyt50mgTotal,
      qtyt60mgTotal,
    } = this.state.currentRecord || {};
    const {
      name,
      email,
      phone1,
      shipping_street,
      shipping_city,
      shipping_state,
      shipping_zip,
      billing_street,
      billing_city,
      billing_state,
      billing_zip,
      practice_owner_first_name,
      practice_owner_last_name,
    } = this.state.currentUser || {};
    const shipping_address =
      shipping_street +
      " " +
      shipping_city +
      " " +
      shipping_state +
      " " +
      shipping_zip;

    const billing_address =
      billing_street +
      " " +
      billing_city +
      " " +
      billing_state +
      " " +
      billing_zip;

    let dataHtml1 = ``;
    let dataHtml2 = ``;
    let dataHtml3 = ``;
    let dataHtml4 = ``;
    let dataHtml5 = ``;
    let dataHtmlnew = ``;
    let dataHtmlnew1 = ``;
    let dataHtmlnew2 = ``;
    let dataHtmlnew3 = ``;
    let dataHtmlnew4 = ``;
    let dataHtmlnew5 = ``;
    let dataHtmlnew6 = ``;
    let dataHtmlnew7 = ``;
    let dataHtmlnew8 = ``;
    let dataHtmlnew9 = ``;
    let dataHtmlnew10 = ``;
    let dataHtmlnew11 = ``;

    if (qty5mgTotal > 0) {
      dataHtml1 =
        dataHtml1 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty5mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide - 2.5mg/2ml (Vial) - 2ml - Box of 10	
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price5mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty5mgTotal}
            </td>
          `;
    }
    if (qty10mgTotal > 0) {
      dataHtml2 =
        dataHtml2 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty10mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide - 2.5mg/4ml (Vial) - 4ml - Box of 10	
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price10mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty10mgTotal}
            </td>
          `;
    }
    if (qty20mgTotal > 0) {
      dataHtml3 =
        dataHtml3 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty20mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide - 5mg/4ml (Vial) - 4ml - Box of 10	
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price20mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty20mgTotal}
            </td>
          `;
    }
    if (qtysemaApiTotal > 0) {
      dataHtml4 =
        dataHtml4 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty_sema_api}
            </td>
            <td style="border: 1px solid;padding:5px">
              API Semaglutide (${sema_api})
            </td>
            <td style="border: 1px solid;padding:5px">
              $${sema_price}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtysemaApiTotal}
            </td>
          `;
    }
    if (qtytirzApiTotal > 0) {
      dataHtml5 =
        dataHtml5 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty_tirz_api}
            </td>
            <td style="border: 1px solid;padding:5px">
              API Tirzepatide (${tirz_api})
            </td>
            <td style="border: 1px solid;padding:5px">
              $${tirz_price}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtytirzApiTotal}
            </td>
          `;
    }
    if (qty1mgTotal > 0) {
      dataHtmlnew =
        dataHtmlnew +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty1mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide-1mg/0.4mL(Vial) - 2.5mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price1mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty1mgTotal}
            </td>
          `;
    }
    if (qty2M5mgTotal > 0) {
      dataHtmlnew1 =
        dataHtmlnew1 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty2M5mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide-2.5mg/1mL(Vial) - 2.5mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price2M5mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty2M5mgTotal}
            </td>
          `;
    }
    if (qty5M2mgTotal > 0) {
      dataHtmlnew2 =
        dataHtmlnew2 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty5M2mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide-5mg/2mL(Vial) - 2.5mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price5M2mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty5M2mgTotal}
            </td>
          `;
    }
    if (qty15mgTotal > 0) {
      dataHtmlnew3 =
        dataHtmlnew3 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty15mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide-7.5mg/3mL(Vial) - 2.5mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price15mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty15mgTotal}
            </td>
          `;
    }
    if (qty40mgTotal > 0) {
      dataHtmlnew4 =
        dataHtmlnew4 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty40mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide-10mg/4mL(Vial) - 2.5mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price40mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty40mgTotal}
            </td>
          `;
    }
    if (qty80mgTotal > 0) {
      dataHtmlnew5 =
        dataHtmlnew5 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qty80mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Semaglutide-20mg/4mL(Vial) - 4mL
            </td>
            <td style="border: 1px solid;padding:5px">
              $${price80mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qty80mgTotal}
            </td>
          `;
    }
    if (qtyt10mgTotal > 0) {
      dataHtmlnew6 =
        dataHtmlnew6 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qtyt10mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Tirzepatide-10mg/0.5mL(Vial) - 20mL
            </td>
            <td style="border: 1px solid;padding:5px">
              $${pricet10mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtyt10mgTotal}
            </td>
          `;
    }
    if (qtyt20mgTotal > 0) {
      dataHtmlnew7 =
        dataHtmlnew7 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qtyt20mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Tirzepatide-20mg/1mL(Vial) - 20mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${pricet20mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtyt20mgTotal}
            </td>
          `;
    }
    if (qtyt30mgTotal > 0) {
      dataHtmlnew8 =
        dataHtmlnew8 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qtyt30mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Tirzepatide-30mg/1.5mL(Vial) - 20mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${pricet30mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtyt30mgTotal}
            </td>
          `;
    }
    if (qtyt40mgTotal > 0) {
      dataHtmlnew9 =
        dataHtmlnew9 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qtyt40mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Tirzepatide-40mg/2mL(Vial) - 20mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${pricet40mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtyt40mgTotal}
            </td>
          `;
    }
    if (qtyt50mgTotal > 0) {
      dataHtmlnew10 =
        dataHtmlnew10 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qtyt50mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Tirzepatide-50mg/2.5mL(Vial) - 20mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${pricet50mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtyt50mgTotal}
            </td>
          `;
    }
    if (qtyt60mgTotal > 0) {
      dataHtmlnew11 =
        dataHtmlnew11 +
        `
            <td style="border: 1px solid;padding:5px">
              ${qtyt60mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              Tirzepatide-60mg/3mL(Vial) - 20mg/ml
            </td>
            <td style="border: 1px solid;padding:5px">
              $${pricet60mg}
            </td>
            <td style="border: 1px solid;padding:5px">
              $${qtyt60mgTotal}
            </td>
          `;
    }

    return (
      <>
        <div style={{ width: "100%" }}>
          <Navbar />
          {this.state.loading && (
            <div className="loading-wrapper">
              <PulseLoader color="#007bff" loading={true} size={20} />
            </div>
          )}
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h1 className="mt-2 text-primary">Order List</h1>
              <br></br>
              {this.props.auth.user.superadmin && (
                <>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">REP</label>
                      <select
                        name="rep"
                        onChange={this.onChange}
                        className="form-control"
                        id="rep"
                      >
                        <option value="" selected="">
                          All
                        </option>
                        {Object.values(members).map((each, i) => (
                          <option
                            key={i}
                            value={each.code}
                            selected={this.state.rep === each.code}
                          >
                            {each.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <button
                        onClick={this.onSearch}
                        type="button"
                        className="btn btn-primary"
                      >
                        Search
                      </button>
                      &nbsp;
                      <button
                        type="reset"
                        onClick={this.resetSearch}
                        className="btn btn-outline-secondary"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </>
              )}
              <ReactDatatable
                config={this.config}
                records={records}
                columns={this.columns}
                onChange={this.filter}
                dynamic={true}
                total_record={this.state.totalCount}
                loading={true}
                exportToCSV={this.exportToCSV}
              />
            </div>
          </div>
          <ToastContainer />

          <div id="showPdf" style={{ display: "none" }}>
            <div
              bgcolor="#f6f6f6"
              style={{
                color: "#333",
                height: "100%",
                width: "100%",
                fontFamily: "sans-serif",
              }}
              height="100%"
              width="100%"
            >
              <table
                bgcolor="#f6f6f6"
                cellspacing="0"
                style={{ padding: "40px", width: "100%" }}
                width="100%"
              >
                <tbody>
                  <tr>
                    <td width="5px" style={{ padding: "5px" }}></td>
                    <td>
                      <table width="100%" cellspacing="0">
                        <tbody>
                          <tr>
                            <td style={{ padding: "5px" }}>
                              <a
                                href="#"
                                style={{ color: "#348eda" }}
                                target="_blank"
                              >
                                <img
                                  src="https://rxreachanalytis.s3.amazonaws.com/pdfs/glp.png"
                                  alt="//glp1rxdirect.com/"
                                  style={{
                                    height: "80px",
                                    maxWidth: "100%",
                                    width: "157px",
                                  }}
                                  height="80"
                                  width="157"
                                />
                              </a>
                            </td>
                            <td
                              style={{ fontSize: "12px", padding: "5px" }}
                              rowspan="2"
                            ></td>
                            <td
                              style={{
                                fontSize: "12px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                              align="right"
                            >
                              <h2>ORDER FORM</h2>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "15px", padding: "5px" }}>
                              PQ Pharmacy 15215 Technology
                              <br />
                              Dr. Brooksville FL 34604
                              <br />
                              (352) 477-8977
                            </td>

                            <td
                              style={{
                                fontSize: "15px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                              align="right"
                            >
                              ORDER No.: {order_id}
                              <br />
                              REP: {store}
                              <br />
                              DATE: {moment(date).format("LL")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="5px" style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td width="5px" style={{ padding: "5px" }}></td>
                    <td>
                      <table width="100%" cellspacing="0">
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "5px",
                                width: "50%",
                                fontSize: "15px",
                              }}
                            >
                              BILL TO:
                              <br />
                              {name}
                              <br />
                              {practice_owner_first_name +
                                " " +
                                practice_owner_last_name}
                              <br />
                              {billing_address}
                              <br />
                              {email}
                              <br />
                              {phone1}
                              <br />
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                width: "50%",
                                fontSize: "15px",
                              }}
                            >
                              SHIP TO:
                              <br />
                              {name}
                              <br />
                              {practice_owner_first_name +
                                " " +
                                practice_owner_last_name}
                              <br />
                              {shipping_address}
                              <br />
                              {email}
                              <br />
                              {phone1}
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="5px" style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td width="5px" style={{ padding: "5px" }}></td>
                    <td>
                      <table width="100%" cellspacing="0">
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "5px",
                                backgroundColor: "#2b3d63 !important",
                                color: "#fff !important",
                                border: "1px solid black",
                              }}
                            >
                              QUANTITY
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                backgroundColor: "#2b3d63 !important",
                                color: "#fff !important",
                                border: "1px solid black",
                              }}
                            >
                              DESCRIPTION
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                backgroundColor: "#2b3d63 !important",
                                color: "#fff !important",
                                border: "1px solid black",
                              }}
                            >
                              UNIT PRICE
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                backgroundColor: "#2b3d63 !important",
                                color: "#fff !important",
                                border: "1px solid black",
                              }}
                            >
                              TOTAL
                            </td>
                          </tr>
                          {dataHtmlnew !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew }}
                            ></tr>
                          )}
                          {dataHtmlnew1 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew1 }}
                            ></tr>
                          )}
                          {dataHtml1 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtml1 }}
                            ></tr>
                          )}
                          {dataHtml2 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtml2 }}
                            ></tr>
                          )}
                          {dataHtmlnew2 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew2 }}
                            ></tr>
                          )}
                          {dataHtmlnew3 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew3 }}
                            ></tr>
                          )}
                          {dataHtml3 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtml3 }}
                            ></tr>
                          )}
                          {dataHtmlnew4 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew4 }}
                            ></tr>
                          )}
                          {dataHtmlnew5 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew5 }}
                            ></tr>
                          )}
                          {dataHtmlnew6 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew6 }}
                            ></tr>
                          )}
                          {dataHtmlnew7 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew7 }}
                            ></tr>
                          )}
                          {dataHtmlnew8 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew8 }}
                            ></tr>
                          )}
                          {dataHtmlnew9 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew9 }}
                            ></tr>
                          )}
                          {dataHtmlnew10 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew10 }}
                            ></tr>
                          )}
                          {dataHtmlnew11 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtmlnew11 }}
                            ></tr>
                          )}
                          {dataHtml4 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtml4 }}
                            ></tr>
                          )}
                          {dataHtml5 !== "" && (
                            <tr
                              dangerouslySetInnerHTML={{ __html: dataHtml5 }}
                            ></tr>
                          )}
                          <tr>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                border: "1px solid",
                              }}
                              colspan="2"
                              rowspan="3"
                            >
                              <i>
                                Credit cards to incur an additional 3% charge
                              </i>
                            </td>
                            <td style={{ border: "1px solid", padding: "5px" }}>
                              SUBTOTAL
                            </td>
                            <td style={{ border: "1px solid", padding: "5px" }}>
                              ${qtyTotal}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid", padding: "5px" }}>
                              SHIPPING
                            </td>
                            <td style={{ border: "1px solid", padding: "5px" }}>
                              ${shipping}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid", padding: "5px" }}>
                              TOTAL
                            </td>
                            <td style={{ border: "1px solid", padding: "5px" }}>
                              ${qtyTotal}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="5px" style={{ padding: "5px" }}></td>
                  </tr>
                  <tr>
                    <td width="5px" style={{ padding: "5px" }}></td>
                    <td>
                      <table width="100%" cellspacing="0">
                        <tbody>
                          <tr>
                            <td style={{ padding: "5px", textAlign: "center" }}>
                              <br />
                              THANK YOU FOR YOUR BUSINESS
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="5px" style={{ padding: "5px" }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, { logoutUser })(OrderList);
